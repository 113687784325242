import React from 'react';
import { useLocation } from 'react-router-dom';

import { useQuery } from '../../hooks/useQuery';
import { Layout } from '../../layout';

export function NotFound() {
    const { op_it } = useQuery({ op_it: '' });
    const { hash, pathname, search, state } = useLocation();

    return (
        <Layout uid={op_it} title="Not Found">
            <div>
                <h2>hash</h2>
                <p>{JSON.stringify(hash, null, 2)}</p>
            </div>
            <div>
                <h2>pathname</h2>
                <p>{JSON.stringify(pathname, null, 2)}</p>
            </div>
            <div>
                <h2>search</h2>
                <p>{JSON.stringify(search, null, 2)}</p>
            </div>
            <div>
                <h2>state</h2>
                <p>{JSON.stringify(state, null, 2)}</p>
            </div>
        </Layout>
    );
}
