import axios from 'axios';

import { getCaptcha } from './recaptcha';
import { getIdentifier } from './fingerprint';

const toroOidcClient = axios.create({ baseURL: '' });

/**
 * Realiza login oauth
 * @param uid
 * @param login 
 * @param password 
 * @returns 
 */
export const login = async (uid: string, login: string, password: string) => {

    const response = await toroOidcClient.post(`/oauth/interaction/${uid}/login`,
        new URLSearchParams({
            login,
            password,
        }),
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                ...(await getBaseHeaders(login)),
            }
        }
    );

    return { redirected: true, location: response.data.location };
};

/**
 * Realiza login oauth com MFA
 * @param uid 
 * @param login 
 * @param password 
 * @param code 
 * @param toroSession 
 * @param rememberToken 
 * @returns 
 */
export const loginWithMFA = async (uid: string, login: string, password: string, code: string, toroSession: string, rememberToken: boolean) => {

    const response = await toroOidcClient.post(`/oauth/interaction/${uid}/mfa`,
        new URLSearchParams({
            login,
            password,
            code,
            toroSession,
            rememberToken: String(rememberToken),
        }),
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                ...(await getBaseHeaders(login, toroSession)),
            }
        }
    );

    return { redirected: true, location: response.data.location };
};

/**
 * Confirma permissões de dados do cliente
 * @param uid 
 * @returns 
 */
export const confirm = async (uid: string) => {
    const response = await toroOidcClient.post(`/oauth/interaction/${uid}/confirm`);
    return response.data;
};

/**
 * Envia MFA via SMS
 * @param login 
 * @param toroSession 
 * @returns 
 */
export const sendSMS = async (login: string, toroSession: string) => {

    const response = await toroOidcClient.post('/oauth/login/sendsms', { login, toroSession }, {
        headers: await getBaseHeaders(login, toroSession),
    });

    return response.data;
};

/**
 * Determina os cabeçalhos base comuns de toda requisição
 * @param login
 * @param uid 
 * @param sessionId 
 * @returns 
 */
const getBaseHeaders = async (login: string, sessionId?: string) => {

    const [captcha, fingerprint] = await Promise.all([
        getCaptcha(),
        getIdentifier(),
    ]);

    return {
        'x-sessionId': sessionId,
        'x-toro-platform-identifier': fingerprint?.visitorId,
        ...captcha.asHeaders(),
        'x-captcha-metadata': btoa(JSON.stringify({
            username: login?.slice(0, Math.min(6, login.length)),
            applicationId: 'toro_auth'
        }))
    }
}
