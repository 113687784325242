import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import useCountDown from 'react-countdown-hook';
import { Layout } from '../../layout';
import { Checkbox } from '../../components/Checkbox';
import { loginWithMFA, sendSMS } from '../../services/toro-oidc';
import { useQuery } from '../../hooks/useQuery';
import { IconButton } from '../../components/IconButton';
import { FullScreenDialog } from '../../components/FullScreenDialog';
import iconSms from '../../assets/icons/sms.svg';
import iconSmsDisable from '../../assets/icons/sms_disable.svg';
import iconStroke from '../../assets/icons/stroke.svg';
import iconHelp from '../../assets/icons/help.svg';
import iconScreen1 from '../../assets/images/screen_1.svg';
import iconScreen2 from '../../assets/images/screen_2.svg';
import iconScreen3 from '../../assets/images/screen_3.svg';
import Carousel, { CarouselItem } from '../../components/Carousel';
import { DownloadQrcode } from '../../container/DownloadQrcode';

type MFAState = { username: string; password: string; lastFour: string; toroSession: string; hasDevice: boolean };

export function MFA() {
    const { op_it } = useQuery({ op_it: '' });

    const [redirectUrl, setRedicrectUrl] = useState('');
    const redirectForm = useRef<HTMLFormElement>(null);

    useEffect(() => {
        if (redirectUrl && redirectForm.current) {
            redirectForm.current.submit();
        }
    }, [redirectUrl, redirectForm]);

    const [errorMessage, setErrorMessage] = useState('');
    const [smsResent, setSmsResent] = useState(false);
    const [hasTokenToro, setTokenToro] = useState(false);
    const [hasImg, setImg] = useState(true);
    const [timeLeft, { start: startTimer, reset: resetTimer }] = useCountDown(60000, 1000);

    const history = useHistory();
    const location = useLocation<MFAState>();

    const username = location?.state?.username;
    const password = location?.state?.password;
    const lastFour = location?.state?.lastFour || 'xxxx';
    const toroSession = location?.state?.toroSession;
    const hasDevice = location?.state?.hasDevice;

    if (!username || !password || !lastFour || !toroSession) {
        history.replace({
            pathname: '/login/error',
            search: location.search
        });
    }

    useEffect(() => {
        handleSendSMS();
    }, []);

    useEffect(() => {
        if (smsResent && timeLeft === 0) {
            setSmsResent(false);
        }
    }, [smsResent, timeLeft]);

    const handleSendSMS = async () => {
        try {
            await sendSMS(username, toroSession);

            setSmsResent(true);
            setTokenToro(false);
        } catch (error: any) {
            if (error.isAxiosError) {
                switch (error.response?.data?.errorCode) {
                    case 'FAILED_RESEND_SMS':
                        setErrorMessage('Falha ao enviar SMS, tente novamente em alguns segundos');
                        break;
                    default:
                        history.push({
                            pathname: '/login/error',
                            search: location.search,
                            state: {
                                errorType: error.response?.data.errorCode || 'UNEXPECTED'
                            }
                        });
                        break;
                }
            }
        } finally {
            resetTimer();
            startTimer();
        }
    };

    const [open, setOpen] = React.useState(false);
    const openModal = async () => {  
        setTokenToro(true);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const renderSubTitle = () => {
        return (
            <React.Fragment>
                Use o <strong>Token</strong> do app da Toro ou o código enviado para o número com final:{' '}
                <strong>{lastFour}</strong>.
            </React.Fragment>
        );
    };

    const renderImgHelp = () => {
        return hasImg ? <img src={iconHelp} alt="" /> : '';
    };

    const renderContentInstruction = () => {
       setImg(false);

        return (
            <Carousel startTime={true}>
                <CarouselItem path={iconScreen1}></CarouselItem>
                <CarouselItem path={iconScreen2}></CarouselItem>
                <CarouselItem path={iconScreen3}></CarouselItem>
            </Carousel>
        );
    };

    const changeText = () => {       
        return hasTokenToro ? 'Autenticação com Token Toro' : 'Autenticação por SMS';
    };

    return (
        <Layout uid={op_it} title={changeText()} subtitle={renderSubTitle()} message={errorMessage}>
            <Formik
                initialValues={{ code: '', rememberToken: true }}
                validationSchema={yup.object().shape({
                    code: yup.string().required('Campo obrigatório'),
                    rememberToken: yup.boolean()
                })}
                onSubmit={async (values, { setSubmitting }) => {
                    const { code, rememberToken } = values;

                    try {
                        console.log('MFA::handleMFASubmit::needMFACode');

                        const response = await loginWithMFA(
                            op_it,
                            username,
                            password,
                            code,
                            toroSession,
                            rememberToken,
                        );

                        setErrorMessage('');

                        if (response?.redirected) {
                            setRedicrectUrl(response?.location);
                        }
                    } catch (error:any) {
                        if (error.isAxiosError) {
                            switch (error.response?.data?.errorCode) {
                                case 'INVALID_TOKEN':
                                    setErrorMessage('Token inválido');
                                    break;
                                default:
                                    history.push({
                                        pathname: '/login/error',
                                        search: `${location.search}&${error.response?.data.errorCode || 'UNEXPECTED'}`
                                    });
                                    break;
                            }
                        }
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({ values, errors, touched, isValid, handleSubmit, handleChange, handleBlur, isSubmitting }) => {
                    const showTokenError = errors.code && touched.code;

                    return (
                        <form autoComplete="off" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <div className={`input-field ${showTokenError ? 'input-error' : ''}`}>
                                    <label htmlFor="login">Digite o código abaixo:</label>
                                    <input
                                        required
                                        type="text"
                                        inputMode="numeric"
                                        name="code"
                                        className="form-input form-field--js"
                                        autoCapitalize="none"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.code}
                                        disabled={isSubmitting}
                                    />
                                    {showTokenError && <span className="input-error-message">{errors.code}</span>}
                                </div>
                            </div>

                            <div className="form-group form-check form-check-inline">
                                <Checkbox
                                    checked={values.rememberToken}
                                    onChange={handleChange}
                                    id="rememberToken"
                                    size="small"
                                    disableRipple
                                />

                                <label className="form-check-label font-silver" htmlFor="rememberToken">
                                    Lembrar por 30 dias.
                                </label>
                            </div>

                            <button
                                type="submit"
                                className="btn btn-primary btn--md btn--full btn--solid btn-primary--solid"
                                disabled={!isValid || isSubmitting}
                            >
                                Confirmar
                            </button>
                            <div className="button-resend-mfa">
                                <span className="font-silver">
                                    {smsResent
                                        ? 'Não recebeu o código? ' 
                                        : 'Se preferir, acesse o código de outra forma: '}
                                    {smsResent && `Reenviar SMS em ${(timeLeft || 1) / 1000}s`}
                                </span>
                            </div>
                            <div className="container-button">
                                <IconButton
                                    isDisabled={smsResent}
                                    label="SMS"
                                    onClick={handleSendSMS}
                                    icon={smsResent ? iconSmsDisable : iconSms}
                                    class={smsResent ? 'btn-outline-primary btn-disable' : 'btn-outline-primary'}
                                />
                                <IconButton label="Token Toro" onClick={openModal} icon={iconStroke} />
                            </div>
                            <FullScreenDialog
                                open={open}
                                template={hasDevice ? renderContentInstruction() : DownloadQrcode()}
                                close={handleClose}
                                icon={renderImgHelp()}
                                label="Ajuda"
                            ></FullScreenDialog>

                            {!hasTokenToro ? (
                                <label className="form-check-label font-silver">
                                    Não tem o aplicativo da Toro?
                                    <a className="link-store" href="https://onelink.to/hnfhjq" target="_blank">
                                        Baixe aqui.
                                    </a>
                                </label>
                            ) : (
                                ''
                            )}
                        </form>
                    );
                }}
            </Formik>
            <form ref={redirectForm} action={redirectUrl} method="get"></form>
        </Layout>
    );
}
