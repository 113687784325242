import React from 'react';


interface IButtonIcon {
    onClick?: any ;
    isDisabled?: boolean;
    icon: string;
    label: string;
    class?: string;
}

export function IconButton(props: IButtonIcon) {
    return (
        <button
            onClick={props.onClick}
            type="button"
            className={['btn', 'btn-primary', 'btn--md', 'btn-icon', props.class].join(' ')}
            disabled={props.isDisabled}
        >
            <img src={props.icon} alt={props.label} />
            {props.label}
        </button>
    );
}
