import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MUICheckbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent'
        },
        color: '#900',
        padding: 0,
        margin: 0,
        marginRight: 8,
        position: 'relative',
        top: '-7px',
        '& svg': {
            width: '28px !important'
        }
    }
});

export function Checkbox(props: CheckboxProps) {
    const classes = useStyles();

    return (
        <MUICheckbox
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<CheckBoxIcon fontSize="small" color="inherit" />}
            {...props}
        />
    );
}
