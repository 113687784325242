import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { Login } from './pages/Login';
import { MFA } from './pages/MFA';
import { Term } from './pages/Term';
import { Consent } from './pages/Consent';
import { NotFound } from './pages/NotFound';
import { Error } from './pages/Error';

export default function Routes() {
    return (
        <Router>
            <Switch>
                <Route exact path="/login">
                    <Login />
                </Route>
                <Route exact path="/login/consent">
                    <Consent />
                </Route>
                <Route path="/login/mfa">
                    <MFA />
                </Route>
                <Route path="/login/term">
                    <Term />
                </Route>
                <Route path="/login/error">
                    <Error />
                </Route>
                <Route path="*">
                    <NotFound />
                </Route>
            </Switch>
        </Router>
    );
}
