import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const DETAILS_KEY = '_d';

type Details = {
    client?: {
        client_id: string;
        client_name: string;
        term_link?: string;
    };
    missingOIDCScope?: string;
    missingResourceScopes?: { [key: string]: Array<string> };
    missingOIDCClaims?: Array<string>;
    params : {
        client_id?: string
        code_challenge?: string
        code_challenge_method?: string
        redirect_uri?: string
        response_type?: string
        scope?: string
    }
};

type GenKeys = {
    [key: string]: any;
};

/**
 *
 * @param keys Set the values to grab from the querystring and their default values
 * @example useQuery({ a: 'default value' })
 *  - With the querystring: "?a=123&b=456" the return will be { a: '123' }
 *  - With the querystring: "?b=456" the return will be { a: 'default value' }
 * @example useQuery({ _d: {} })
 *  - We have a special treatment for the "_d" param to decode it
 *  - With the querystring "?_d=eyJhIjoiMTIzIn0=" the return will be { a: '123' }
 */
export function useQuery<Keys extends GenKeys>(keys: Keys): Keys & { _d?: Details | undefined } {
    const { search } = useLocation();

    const query = useMemo(() => {
        const qs = new URLSearchParams(search);

        return Object.entries(keys)
            .map(([key, defaultValue]) => {
                const value = qs.get(key) || '';

                const parsedValue = key === DETAILS_KEY && value ? JSON.parse(atob(value)) : value;

                return {
                    [key]: parsedValue || defaultValue
                };
            })
            .reduce((acc, obj) => ({ ...acc, ...obj }), {});
    }, [search, keys]);

    return query as Keys;
}
