import React from 'react';

import { UnexpectedError } from './Unexpected';
import { SessionExpired } from './SessionExpired';

import { useQuery } from '../../hooks/useQuery';

type ErrorTypes = 'UNEXPECTED' | 'SESSION_EXPIRED';

export function Error() {
    const { e } = useQuery<{ e: ErrorTypes }>({ e: 'UNEXPECTED' });

    const errorUpercased = e.toUpperCase() as ErrorTypes;

    switch (errorUpercased) {
        case 'SESSION_EXPIRED':
            return <SessionExpired />;

        default:
            return <UnexpectedError />;
    }
}
