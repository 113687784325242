import React from 'react';

import { Layout } from '../../layout';

import warningIcon from '../../assets/icons/warning.svg';
import { useQuery } from '../../hooks/useQuery';

export function SessionExpired() {
    const { op_it } = useQuery({ op_it: '' });

    return (
        <Layout uid={op_it} showLogo={false}>
            <div className="mt-3 mb-3">
                <img src={warningIcon} alt="Sessão expirada" />
            </div>
            <div className="mt-4 mb-4">
                <h2>Sua sessão expirou</h2>
            </div>

            <div className="mt-3 mb-3">
                <p>Reinicie o processo de login novamente</p>
            </div>
        </Layout>
    );
}
