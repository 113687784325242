import React, { FormEventHandler } from 'react';

import { Layout } from '../../layout';
import { confirm } from '../../services/toro-oidc';
import { useQuery } from '../../hooks/useQuery';

export function Consent() {
    console.log('Consent::Start');
    const { op_it, _d: details } = useQuery({ op_it: '', _d: {} });

    const askingPreviousAuth =
        [details?.missingOIDCScope, details?.missingOIDCClaims, details?.missingResourceScopes].filter(Boolean)
            .length === 0;

    const missingOIDCScope = new Set(details?.missingOIDCScope);
    missingOIDCScope.delete('openid');
    missingOIDCScope.delete('offline_access');

    const renderMissingOIDCScopes = () => {
        const scopeList: React.ReactNode[] = [];

        missingOIDCScope.forEach((scope) => scopeList.push(<li key={scope}>{scope}</li>));

        return scopeList;
    };

    const missingOIDCClaim = new Set(details?.missingOIDCClaims);
    missingOIDCClaim.delete('openid');
    missingOIDCClaim.delete('offline_access');

    const renderMissingOIDCClaims = () => {
        const claimList: React.ReactNode[] = [];

        missingOIDCClaim.forEach((claim) => claimList.push(<li key={claim}>{claim}</li>));

        return claimList;
    };

    const missingResourceScopes = details?.missingResourceScopes || {};

    const renderResourceScopes = () => {
        return Object.entries(missingResourceScopes).map(([indicator, scopes]) => {
            return (
                <React.Fragment>
                    <li>{indicator}</li>
                    <ul>
                        {scopes.map((scope) => (
                            <li>{scope}</li>
                        ))}
                    </ul>
                </React.Fragment>
            );
        });
    };

    const handleSubmit: FormEventHandler = async (e) => {
        e.preventDefault();

        await confirm(op_it);
    };

    return (
        <Layout uid={op_it} title="Consentimento">
            <ul>
                {askingPreviousAuth && (
                    <li>
                        <span className="client-name text-primary">{details?.client?.client_name}</span> está
                        solicitando sua confirmação de uma autorização concedida previamente
                    </li>
                )}
            </ul>

            {!!missingOIDCScope.size && (
                <React.Fragment>
                    <li>scopes:</li>
                    <ul>{renderMissingOIDCScopes()}</ul>
                </React.Fragment>
            )}

            {!!missingOIDCClaim.size && (
                <React.Fragment>
                    <li>claims:</li>
                    <ul>{renderMissingOIDCClaims()}</ul>
                </React.Fragment>
            )}

            {missingResourceScopes && renderResourceScopes()}

            <form autoComplete="off" onSubmit={handleSubmit}>
                <button
                    autoFocus
                    type="submit"
                    className="btn btn-primary btn--md btn--full btn--solid btn-primary--solid"
                >
                    Continuar
                </button>
            </form>
        </Layout>
    );
}
