import React, { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import iconArrowLeft from '../assets/icons/ic-arrow-left.svg';

export const CarouselItem = (props: any): any => {
    return (
        <div className="carousel-item-auth">
            <img className="carousel-image" src={props.path} alt="" />
        </div>
    );
};

function getTitle(index: number) {
    const arrayLabel = [
        '',
        'Abra o aplicativo da Toro.',
        'Copie o código gerado.',
        'Volte ao navegador, insira o código e pronto!'
    ];
    return arrayLabel[index];
}

const Carousel = ({ children, startTime }: any) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [paused, setPaused] = useState(false);

    const updateIndex = (newIndex: number) => {
        debugger;

        if (newIndex < 0) {
            newIndex = React.Children.count(children) - 1;
        } else if (newIndex >= React.Children.count(children)) {
            newIndex = 0;
        }

        setActiveIndex(newIndex);
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => updateIndex(activeIndex + 1),
        onSwipedRight: () => updateIndex(activeIndex - 1)
    });

    return (
        <div
            {...handlers}
            className="onboarding-carousel"
            onMouseEnter={() => setPaused(true)}
            onMouseLeave={() => setPaused(false)}
        >
            <div className="carousel-item-auth">
                <div className="carousel-header">
                    <div className="number-content">{activeIndex + 1} </div>
                    <div className="text-content">{getTitle(activeIndex + 1)}</div>
                </div>
                <div className="carousel-image-page">
                    <div
                        className="page-button"
                        onClick={() => {
                            updateIndex(activeIndex - 1);
                        }}
                    >
                        <img src={iconArrowLeft} alt="" />
                    </div>

                    <div className="inner">
                        {React.Children.map(children, (child: any, index) => {
                            return activeIndex == index ? React.cloneElement(child) : undefined;
                        })}
                    </div>
                    <div
                        className="page-button next"
                        onClick={() => {
                            updateIndex(activeIndex + 1);
                        }}
                    >
                        <img src={iconArrowLeft} alt="" />
                    </div>
                </div>
            </div>
            <div className="carousel-indicator">
                {React.Children.map(children, (child, index) => {
                    return (
                        <div
                            className={`${index === activeIndex ? 'indicator active' : 'indicator'}`}
                            onClick={() => {
                                updateIndex(index);
                            }}
                        ></div>
                    );
                })}
            </div>
        </div>
    );
};

export default Carousel;
