export const getCaptcha = async () => {
    const action = 'LOGIN';
    const siteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_ENTERPRISE_SITE_KEY;
    const token = await grecaptcha.enterprise.execute(siteKey, { action });

    return {
        token,
        action,
        siteKey,
        asHeaders: () => ({
            'x-type': 'enterprise',
            'x-action': action,
            'x-captcha': token,
            'x-captcha-sitekey': siteKey,
        })
    }
}